import React, { useCallback, useContext, useMemo, useState } from 'react';

type SkipToLink = {
  name: string;
  key: string;
  ref: React.RefObject<HTMLElement>;
};

type SkipToLinks = SkipToLink[];

export interface SkipLinksContextType {
  addSkipLink: (key: string, name: string, ref: React.RefObject<HTMLElement>) => void;
  links: SkipToLinks;
}

const SkipLinksContext = React.createContext<SkipLinksContextType>({
  addSkipLink: () => {},
  links: [],
});

export const useSkipLink = () => useContext(SkipLinksContext);

export const SkipLinkProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [links, setLinks] = useState<SkipToLinks>([]);

  const addSkipLink = useCallback(
    (key: string, name: string, ref: React.RefObject<HTMLElement>) => {
      setLinks((links) => {
        const isUnique = links.every((link) => link.key !== key);

        if (isUnique) {
          return [...links, { key, name, ref }];
        }

        return links;
      });

      return () => setLinks((links) => links.filter((link) => link.key !== key));
    },
    []
  );

  const contextValue = useMemo(() => {
    return {
      links,
      addSkipLink,
    };
  }, [links, addSkipLink]);

  return <SkipLinksContext.Provider value={contextValue}>{children}</SkipLinksContext.Provider>;
};
