import axios from 'axios';
import { LegacyPrescriptionBagsResponse } from './types';

export const createApiClient = (baseUrl: string) => {
  const kesClient = axios.create({
    baseURL: baseUrl,
    maxRedirects: 0,
    headers: {
      Accept: 'application/vnd.kes.web+json',
    },
  });

  const shibbolethClient = axios.create();

  return {
    getAxiosInstance: () => kesClient,
    getShibbolethAxiosInstance: () => shibbolethClient,
    checkAuthentication: async () => {
      try {
        await kesClient.get('/authorized', {
          params: {
            _: Date.now(),
          },
        });
        return true;
      } catch (e) {
        return false;
      }
    },
    checkAuthToken: async () => {
      const response = await shibbolethClient.get('/Shibboleth.sso/Session');

      return Object.keys(response.data).length > 0;
    },
    initialize: async (cartId: string, userId: string) => {
      await kesClient.get('/initiering', {
        params: {
          cartId,
          userId,
          _: Date.now(),
        },
      });
    },
    getCart: async () => {
      const response = await kesClient.get<LegacyPrescriptionBagsResponse>('/receptpasar');

      return response.data;
    },
    removeItem: async (itemId: string) => {
      await kesClient.delete('/receptpasar', {
        params: {
          itemId,
        },
      });
    },
    removeAnimalItem: async (itemId: string) => {
      await kesClient.delete(`/receptpasar/djurvaror/${itemId}`);
    },
  };
};

export type ApiClient = ReturnType<typeof createApiClient>;
