import { getListByValueFromStorage } from '@packages/analytics';
import { CartRow } from '@packages/cart';
import { filterUnique, isDefined, uniqueArray } from '@packages/utils';
import { ORDER_CONFIRMED } from '../../../../../../checkout/analytics';
import { SummarizedLegacyPrescriptionBag } from '../../../../../../components/rxCart/LegacyRxPrescriptionBag';
import { generateHashId } from '../../../../../../utils/generateHashId';
import { createSimpleTracker } from '../utils/createSimpleTracker';
import { formatFakeRxProduct, formatProduct } from '../utils/formatProduct';
import { shouldTrackProduct } from '../utils/shouldTrackProduct';

export const createUniqueTransactionCoupon = (rows: CartRow[]): string[] => {
  const coupons: string[] = [];

  rows.forEach((row) => {
    if (shouldTrackProduct(row)) {
      row.cartRowPromotions.forEach((promotion) => {
        coupons.push(promotion.name ? promotion.name : 'N/A');
      });
    }
  });

  return uniqueArray(coupons, (c) => c);
};

interface RowMetrics {
  quantity: number;
  coupon: string;
  metric1: number;
  metric2: number | string;
  metric3: number;
  metric4: number;
  list: string;
}

type FormattedRow = ReturnType<typeof formatProduct> & RowMetrics;

const createOrderRowWithMetrics = (
  formattedProduct: ReturnType<typeof formatProduct>,
  metrics: {
    quantity: number;
    coupons: string[];
    discountRate: number;
    rxCustomerPrice?: number;
    totalRowRevenueExcludingVat: number;
    totalRowRevenue: number;
  }
): FormattedRow => ({
  ...formattedProduct,
  quantity: metrics.quantity,
  coupon: metrics.coupons.filter(filterUnique).join(',') || 'N/A',
  metric1: metrics.discountRate,
  metric2: metrics.rxCustomerPrice ?? 'N/A',
  metric3: metrics.totalRowRevenueExcludingVat,
  metric4: metrics.totalRowRevenue,
  list: getListByValueFromStorage({ value: formattedProduct.id }) || 'N/A',
});

export const purchaseTracker = createSimpleTracker({
  [ORDER_CONFIRMED]: async ({ event }, { itemWeb }) => {
    const summary = event.payload.summary;

    const products = await itemWeb.getItems(summary.rows.map((row) => row.itemId));

    const enrichedRows = summary.rows
      .map((row: CartRow) => {
        const product = products.find((p) => p.productNumber === row.itemId);

        if (!product) {
          return null;
        }

        return {
          cartRow: row,
          product,
        };
      })
      .filter(isDefined);

    const formattedRows: FormattedRow[] = enrichedRows.map(({ product, cartRow }) =>
      createOrderRowWithMetrics(formatProduct(product, { price: cartRow.unitNetPrice / 100 }), {
        quantity: cartRow.quantity,
        coupons: shouldTrackProduct(product)
          ? cartRow.cartRowPromotions.map((promotion) => promotion.name)
          : [],
        discountRate: cartRow.totalRowDiscount / cartRow.quantity / 100,
        totalRowRevenueExcludingVat:
          cartRow.quantity * (cartRow.unitNetPrice / 100 / (1 + product.vatRate / 100)),
        totalRowRevenue: (cartRow.quantity * cartRow.unitNetPrice) / 100,
      })
    );

    let rxItem: SummarizedLegacyPrescriptionBag = {
      paidByCustomer: 0,
      paidByInsurance: 0,
      quantity: 0,
    };

    if (summary.rxOrder) {
      const rxItemString = sessionStorage.getItem('rxItem');

      if (rxItemString) {
        rxItem = JSON.parse(rxItemString) as SummarizedLegacyPrescriptionBag;
        sessionStorage.removeItem('rxItem');

        const totalPaid = rxItem.paidByCustomer + rxItem.paidByInsurance;

        formattedRows.push(
          createOrderRowWithMetrics(formatFakeRxProduct(totalPaid / rxItem.quantity), {
            quantity: rxItem.quantity,
            coupons: [],
            discountRate: 0,
            rxCustomerPrice: rxItem.paidByCustomer,
            totalRowRevenueExcludingVat: totalPaid,
            totalRowRevenue: totalPaid,
          })
        );
      }
    }

    if (summary.newPrescriptionBag) {
      const prescriptionBag = summary.newPrescriptionBag;

      const totalPaid = prescriptionBag.totalPrice / 100;

      formattedRows.push(
        createOrderRowWithMetrics(formatFakeRxProduct(totalPaid / prescriptionBag.quantity), {
          quantity: prescriptionBag.quantity,
          coupons: [],
          discountRate: 0,
          rxCustomerPrice: prescriptionBag.customerPrice / 100,
          totalRowRevenueExcludingVat: totalPaid,
          totalRowRevenue: totalPaid,
        })
      );

      rxItem = {
        paidByCustomer: prescriptionBag.customerPrice / 100,
        paidByInsurance: prescriptionBag.priceReduction / 100,
        quantity: prescriptionBag.quantity,
      };
    }

    const taxAmount = summary.orderTaxAmount / 100;
    const revenueExcludingVAT = summary.orderNetPrice / 100 + rxItem.paidByInsurance - taxAmount;

    return {
      platform: 'K2',
      event: 'purchase',
      ecommerce: {
        currencyCode: 'SEK',
        purchase: {
          actionField: {
            action: 'purchase',
            affiliation: 'kronans',
            id: generateHashId(),
            revenue: revenueExcludingVAT,
            revenueInclVAT: revenueExcludingVAT + taxAmount,
            tax: summary.orderTaxAmount / 100,
            nonRXtransactionTotalExclVAT: (summary.tgPriceExclVat - summary.bonusCheckAmount) / 100,
            shipping: summary.shippingPrice / 100,
            shippingMethod: summary.shippingMethod,
            coupon: createUniqueTransactionCoupon(summary.rows).join(','),
            discountCode:
              summary.vouchers && summary.vouchers.length > 0 ? summary.vouchers[0].code : 'N/A',
            pickupLocation: summary.pickupLocation ? summary.pickupLocation : 'N/A',
            itemgroups: formattedRows
              .map((p) => p.dimension9)
              .filter((v) => v !== 'N/A')
              .filter(filterUnique)
              .sort()
              .join(','),
            bonuscheck: summary.bonusCheckAmount / 100,
          },
          products: formattedRows,
        },
      },
    };
  },
});
