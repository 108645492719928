import { createApiClient } from '@apis/kes';
import { useCart } from '@packages/cart';
import debounce from 'lodash-es/debounce';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LegacyRxCartContext } from './Context';

type RxCartEvent = CustomEvent<{
  rxCount: number;
}>;

const isRxCartEvent = (event: Event): event is RxCartEvent =>
  typeof (event as RxCartEvent)?.detail?.rxCount !== 'undefined';

interface Props {
  baseUrl: string;
}

export const Provider: React.FC<React.PropsWithChildren<Props>> = ({ baseUrl, children }) => {
  const { cart, touch, destroyRx, refresh } = useCart();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState<number | null>(null);
  const apiClient = useMemo(() => createApiClient(baseUrl), [baseUrl]);
  const previousNumberOfItems = useRef(numberOfItems);

  const updateStatus = useCallback(async () => {
    try {
      const isAuthenticated = await apiClient.checkAuthToken();
      await apiClient.checkAuthentication();
      setIsAuthenticated(isAuthenticated);
      return isAuthenticated;
    } catch (e) {
      setIsAuthenticated(false);
      return false;
    }
  }, [apiClient]);

  useEffect(() => {
    if (
      cart?.rxOrder &&
      isAuthenticated &&
      previousNumberOfItems.current !== numberOfItems &&
      previousNumberOfItems.current !== null
    ) {
      refresh();
    }
    previousNumberOfItems.current = numberOfItems;
  }, [cart?.rxOrder, isAuthenticated, numberOfItems, refresh]);

  useEffect(() => {
    if (cart && cart.rxOrder && isAuthenticated && previousNumberOfItems.current === null) {
      try {
        const itemsInLocalStorage = localStorage.getItem('rxCartCount');

        if (itemsInLocalStorage) {
          setNumberOfItems(parseInt(itemsInLocalStorage, 10));
        } else {
          setNumberOfItems(0);
        }
      } catch (e) {
        setNumberOfItems(0);
      }
    }
  }, [cart, isAuthenticated]);

  useEffect(() => {
    const syncCurrentState = async () => {
      const newStatus = await updateStatus();

      if (!newStatus) {
        try {
          await destroyRx();
          const event: RxCartEvent = new CustomEvent('rxCart', { detail: { rxCount: 0 } });
          window.dispatchEvent(event);
        } catch (e) {
          // Ignore error as destroy:ing rx cart is not crucial
        }
      }
    };

    if (cart && cart.rxOrder && !isAuthenticated) {
      syncCurrentState();
    }
  }, [cart, isAuthenticated, updateStatus, destroyRx]);

  useEffect(() => {
    if (cart && cart.rxOrder && isAuthenticated) {
      const FIVE_MINUTES = 1000 * 60 * 5;

      const interval = setInterval(async () => {
        updateStatus();
        touch();
      }, FIVE_MINUTES);

      return () => {
        clearInterval(interval);
      };
    }
  }, [apiClient, cart, isAuthenticated, updateStatus, touch]);

  useEffect(() => {
    const rxCartChangesHandler = (event: Event) => {
      if (isRxCartEvent(event)) {
        const newNumberOfItems = event.detail.rxCount;

        if (newNumberOfItems !== numberOfItems) {
          setNumberOfItems(newNumberOfItems);
        }
      }
    };

    const debouncedCartChangeHandler = debounce(rxCartChangesHandler, 500);

    window.addEventListener('rxCart', debouncedCartChangeHandler);

    return () => {
      window.removeEventListener('rxCart', debouncedCartChangeHandler);
    };
  }, [numberOfItems]);

  return (
    <LegacyRxCartContext.Provider value={{ isAuthenticated, apiClient, kesBaseUrl: baseUrl }}>
      {children}
    </LegacyRxCartContext.Provider>
  );
};
