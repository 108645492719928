import { useHasAcceptedCookieCategories } from '@oriola-corporation/kronan-cookies';
import { FeatureFlagsProvider } from '@packages/config';
import { TestFreaksProvider } from '@packages/testfreaks';
import React from 'react';
import { DatadogProvider } from '../../containers/integrations/datadogProvider';
import { KlevuSearchProvider } from '../../klevu';

type PropsWithoutChildren<T extends React.ElementType> = Omit<React.ComponentProps<T>, 'children'>;

export type DatadogConfig = PropsWithoutChildren<typeof DatadogProvider>;

export type TestFreaksConfig = PropsWithoutChildren<typeof TestFreaksProvider>;

export type KlevuConfig = PropsWithoutChildren<typeof KlevuSearchProvider>;

export type FeatureFlagsConfig = PropsWithoutChildren<typeof FeatureFlagsProvider>;

interface Props {
  datadog: DatadogConfig;
  testFreaks: TestFreaksConfig;
  klevu: KlevuConfig;
  featureFlags: FeatureFlagsConfig;
}

export const EnvironmentProvider: React.FC<React.PropsWithChildren<Props>> = ({
  datadog,
  testFreaks,
  klevu,
  featureFlags,
  children,
}) => {
  const { hasAccepted: hasAcceptedStatisticsCookies } = useHasAcceptedCookieCategories([
    'statistics',
  ]);
  return (
    <FeatureFlagsProvider {...featureFlags}>
      <TestFreaksProvider {...testFreaks}>
        <KlevuSearchProvider {...klevu}>
          <DatadogProvider {...datadog} enabled={hasAcceptedStatisticsCookies} />
          {children}
        </KlevuSearchProvider>
      </TestFreaksProvider>
    </FeatureFlagsProvider>
  );
};
