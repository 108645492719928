export const getValueFromStorage = <T>(my_key: string): T | undefined => {
  try {
    const { [my_key]: value }: Storage = localStorage;

    return JSON.parse(value) as T;
  } catch (error) {
    return undefined;
  }
};

export const setValueToStorage = <T>(my_key: string, value: T) => {
  try {
    localStorage.setItem(my_key, JSON.stringify(value));
  } catch (error) {
    return undefined;
  }
};

export const removeKeyFromStorage = (my_key: string): void => {
  try {
    localStorage.removeItem(my_key);
  } catch (error) {
    return undefined;
  }
};
