import {
  EMPTY_SEARCH_RESULT_VIEWED,
  SEARCH_PAGE_VIEWED,
} from '../../../../../../components/search-page/analytics';
import { createSimpleTracker } from '../utils/createSimpleTracker';

export const miscTracker = createSimpleTracker({
  [SEARCH_PAGE_VIEWED]: ({ event: { payload } }) => {
    const { searchTerm } = payload;
    return {
      event: 'view_search_results',
      search_term: searchTerm,
    };
  },
  [EMPTY_SEARCH_RESULT_VIEWED]: ({ event: { payload } }) => {
    const { searchTerm } = payload;
    return {
      event: 'no_search_results',
      search_term: searchTerm,
    };
  },
});
