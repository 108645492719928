export const SEARCH_PAGE_VIEWED = 'SEARCH_PAGE/VIEWED';
export interface SearchPageViewed {
  type: typeof SEARCH_PAGE_VIEWED;
  payload: {
    searchTerm: string;
  };
}

export const EMPTY_SEARCH_RESULT_VIEWED = 'SEARCH_PAGE/EMPTY_SEARCH_RESULT_VIEWED';
export interface EmptySearchResultViewed {
  type: typeof EMPTY_SEARCH_RESULT_VIEWED;
  payload: {
    searchTerm: string;
  };
}

export type AnalyticsEvents = SearchPageViewed | EmptySearchResultViewed;
