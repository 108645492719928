import { getListByValueFromStorage } from '@packages/analytics';
import { isDefined } from '@packages/utils';
import {
  CHECKOUT_BILLING_CHANGED,
  CHECKOUT_DELIVERY_VIEWED,
  CHECKOUT_PAGE_VIEWED,
  CHECKOUT_PAYMENT_VIEWED,
  CHECKOUT_SHIPPING_CHANGED,
} from '../../../../../../checkout/analytics';
import { createSimpleTracker } from '../utils/createSimpleTracker';
import { formatFakeRxProduct, formatProduct } from '../utils/formatProduct';

export const checkoutTracker = createSimpleTracker({
  [CHECKOUT_PAGE_VIEWED]: async ({ event }, { itemWeb }) => {
    const { cart } = event.payload;

    if (cart.rows.length === 0) {
      return;
    }

    const items = await itemWeb.getItems(cart.rows.map(({ itemId }) => itemId));

    const itemsToReport = cart.rows
      .map(({ itemId, quantity }) => {
        const item = items.find(({ productNumber }) => productNumber === itemId);

        if (!item) {
          return null;
        }

        return {
          ...formatProduct(item),
          quantity,
          list: getListByValueFromStorage({ value: item.productNumber }) || 'N/A',
        };
      })
      .filter(isDefined);

    const prescriptionBag = cart.newPrescriptionBag;

    if (prescriptionBag?.status === 'INCLUDED' && prescriptionBag.content.totalRows > 0) {
      itemsToReport.push({
        ...formatFakeRxProduct(
          (prescriptionBag.content.totalPrice - prescriptionBag.content.vatAmount) /
            (100 * prescriptionBag.content.quantity)
        ),
        quantity: prescriptionBag.content.quantity,
        list: getListByValueFromStorage({ value: prescriptionBag.prescriptionBagId }) || 'N/A',
      });
    }

    if (itemsToReport.length > 0) {
      return {
        platform: 'K2',
        event: 'checkout',
        event_info: {
          event_name: 'begin_checkout',
        },
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
            },
            products: itemsToReport,
          },
        },
      };
    }
  },
  [CHECKOUT_DELIVERY_VIEWED]: async ({ event, metadata }, { itemWeb }) => {
    return {
      event: 'checkout',
      event_info: {
        event_name: 'delivery_view',
      },
    };
  },
  [CHECKOUT_PAYMENT_VIEWED]: async ({ event, metadata }, { itemWeb }) => {
    return {
      event: 'checkout',
      event_info: {
        event_name: 'payment_view',
      },
    };
  },
  [CHECKOUT_BILLING_CHANGED]: async ({ event, metadata }, { itemWeb }) => {
    return {
      event: 'checkout_payment',
      event_info: {
        event_name: 'payment_address_changed',
      },
    };
  },
  [CHECKOUT_SHIPPING_CHANGED]: async ({ event, metadata }, { itemWeb }) => {
    return {
      event: 'checkout_payment',
      event_info: {
        event_name: 'delivery_address_changed',
      },
    };
  },
});
