import { AnalyticsContextProvider as BaseAnalyticsContextProvider } from '@oriola-corporation/kronan-analytics';
import { CookieCategory, useAcceptedCookieCategories } from '@oriola-corporation/kronan-cookies';
import React, { useEffect, useRef } from 'react';
import { BaseAnalyticsEvent, UnknownObject } from './types';

interface AnalyticsContextValue {
  onEvent: (event: BaseAnalyticsEvent, metadata: UnknownObject) => void;
}

interface AnalyticsProviderProps extends AnalyticsContextValue {
  onUpdateCookieConsent?: (categories: CookieCategory[]) => void;
}

export const AnalyticsContextProvider: React.FC<
  React.PropsWithChildren<AnalyticsProviderProps>
> = ({ onEvent, onUpdateCookieConsent, children }) => {
  const { isInitialized, acceptedCategories } = useAcceptedCookieCategories();

  const isEnabled = acceptedCategories.includes('necessary');

  const isEnabledRef = useRef(isEnabled);

  useEffect(() => {
    isEnabledRef.current = isEnabled;
  }, [isEnabled]);

  useEffect(() => {
    if (isInitialized) {
      onUpdateCookieConsent && onUpdateCookieConsent(acceptedCategories);
    }
  }, [acceptedCategories, isInitialized, onUpdateCookieConsent]);

  return (
    <BaseAnalyticsContextProvider isEnabled={isEnabled} onEvent={onEvent}>
      {children}
    </BaseAnalyticsContextProvider>
  );
};
