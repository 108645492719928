import './src/styles/global.css';
import { isServer } from '@packages/gatsby-utils';
import { RouteUpdateArgs, ShouldUpdateScrollArgs } from 'gatsby';
import { PREVIOUS_PATH_KEY } from './src/contexts/PreviousLocationContext';
import wrapPageElement from './src/wrapPageElement';
import wrapRootElement from './src/wrapRootElement';

export const onRouteUpdate = ({ prevLocation }: RouteUpdateArgs) => {
  if (prevLocation?.pathname) {
    if (!isServer()) {
      window.sessionStorage.setItem(PREVIOUS_PATH_KEY, prevLocation?.pathname);
    }
  }
};
export { wrapRootElement, wrapPageElement };

export const shouldUpdateScroll = ({ routerProps }: ShouldUpdateScrollArgs) => {
  const { location } = routerProps;
  if (location.pathname === '/hitta-apotek') return false;
  if (preventScrolling(location)) {
    return false;
  }

  return true;
};

const ignoredQueryParams = ['q'];
const blacklistedQueryParams = ['page'];

const preventScrolling = (location: Location) => {
  const params = new URLSearchParams(location.search);
  ignoredQueryParams.forEach((param) => params.delete(param));

  return blacklistedQueryParams.some((param) => params.has(param));
};
