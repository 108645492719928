import {
  GrowthBook,
  GrowthBookProvider as GBProvider,
  useFeature as _useFeature,
  useFeatureIsOn as _useFeatureIsOn,
  useGrowthBook as _useGrowthBook,
} from '@growthbook/growthbook-react';
import { useHasAcceptedCookieCategories } from '@oriola-corporation/kronan-cookies';
import { getConfigurationVariable } from '@packages/config';
import { isServer } from '@packages/gatsby-utils';
import { AppFeatures } from '@packages/growthbook';
import React, { useEffect } from 'react';

const IS_PROD = getConfigurationVariable('ENVIRONMENT') === 'prod';
const IS_ACC1 = getConfigurationVariable('KRONAN_ENV') === 'acc1';
const GROWTH_BOOK_CLIENT_KEY = getConfigurationVariable('GROWTH_BOOK_CLIENT_KEY');

const getClientId = () => {
  // if document and cookie exists
  if (!isServer() && document && document?.cookie) {
    // get _ga cookie value
    const gaCookie = document?.cookie.match(/_ga=(.+?);/);
    if (gaCookie) {
      // get last 2 parts of _ga cookie value
      const gaCookieParts = gaCookie[1].split('.').slice(-2);
      // join last 2 parts of _ga cookie value
      const clientId = gaCookieParts.join('.');
      return clientId;
    }
  } else {
    return null;
  }
};

const growthBook = new GrowthBook<AppFeatures>({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: GROWTH_BOOK_CLIENT_KEY,
  enableDevMode: !IS_PROD || IS_ACC1,
  trackingCallback: (experiment, result) => {
    if ('dataLayer' in window) {
      window &&
        window.dataLayer?.push({
          event: 'experiment_viewed',
          event_info: {
            experiment_id: experiment.key,
            variation_id: `${result.variationId}`,
          },
        });
    }
  },
});

export const GrowthBookProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { hasAccepted, isInitialized } = useHasAcceptedCookieCategories(['statistics']);
  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    const clientId = getClientId();
    growthBook.setAttributes({
      user_pseudo_id: clientId,
      id: clientId,
      consent: hasAccepted,
    });
    growthBook.loadFeatures({
      autoRefresh: true,
      timeout: 2000,
    });
  }, [isInitialized, hasAccepted]);
  return <GBProvider growthbook={growthBook}>{children}</GBProvider>;
};
export const useGrowthBook = (): GrowthBook<AppFeatures> | undefined =>
  _useGrowthBook<AppFeatures>();
export const useFeatureIsOn = (id: keyof AppFeatures & string): boolean =>
  _useFeatureIsOn<AppFeatures>(id);

export const useFeature = (id: keyof AppFeatures & string) => _useFeature(id);

interface FeatureProps<T extends keyof AppFeatures> {
  id: T;
  render: (value: AppFeatures[T] | boolean) => React.ReactNode;
  renderLoading?: () => React.ReactNode;
  useFallback?: boolean;
}

export const GrowthBookFeature = <T extends keyof AppFeatures>({
  id,
  render,
  renderLoading = () => null,
  useFallback = false,
}: FeatureProps<T>) => {
  const feature = useFeature(id);
  const gb = useGrowthBook();

  if (gb?.ready === false && !useFallback) {
    return <>{renderLoading()}</>;
  }

  if (feature.value === null && feature.source === 'unknownFeature') {
    if (useFallback) {
      return <>{render(feature.on)}</>;
    }
    return <>{renderLoading()}</>;
  }

  return <>{render(feature.value)}</>;
};

export const GrowthBookBlocker = (props: {
  blockedCondition: boolean;
  children: React.ReactElement;
}) => {
  const { blockedCondition } = props;
  if (blockedCondition) {
    return null;
  } else {
    return <>{props.children}</>;
  }
};

export { AppFeatures } from './app-features';
