import { ApiClient, createApiClient } from '@apis/kes';
import { createContext } from 'react';

export interface LegacyRxCartContextType {
  isAuthenticated: boolean;
  kesBaseUrl: string;
  apiClient: ApiClient;
}

export const LegacyRxCartContext = createContext<LegacyRxCartContextType>({
  isAuthenticated: false,
  kesBaseUrl: '/kes',
  apiClient: createApiClient('/kes'),
});
