import { CookieCategory } from '@oriola-corporation/kronan-cookies';
import { AnalyticsContextProvider, BaseAnalyticsEvent } from '@packages/analytics';
import { useApi } from '@packages/api';
import React, { useCallback, useMemo } from 'react';
import { TRACKERS } from './trackers';
import { AnalyticsEvents, Metadata } from './types';

declare global {
  interface Window {
    dataLayer?: Array<unknown>;
    gtag?: (...args: unknown[]) => void;
  }
}

const mapToGoogleConsent = (categories: CookieCategory[]) => {
  const getCategoryStatus = (category: CookieCategory) =>
    categories.includes(category) ? 'granted' : 'denied';

  return {
    functional_storage: getCategoryStatus('necessary'),
    personalization_storage: getCategoryStatus('personalization'),
    analytics_storage: getCategoryStatus('statistics'),
    ad_storage: getCategoryStatus('marketing'),
    ad_user_data: getCategoryStatus('marketing'),
    ad_personalization: getCategoryStatus('marketing'),
  };
};

const handleUpdatedCookieConsent = (categories: CookieCategory[]) => {
  window.gtag && window.gtag('consent', 'update', mapToGoogleConsent(categories));
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'consentUpdated' });
};

export const AnalyticsTracker: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { itemWeb } = useApi();

  const services = useMemo(() => ({ itemWeb }), [itemWeb]);

  const handleEvent = useCallback(
    (genericEvent: BaseAnalyticsEvent, metadata: Metadata) => {
      const event = genericEvent as AnalyticsEvents;

      TRACKERS.forEach((tracker) => {
        tracker(
          {
            event,
            metadata,
          },
          services
        );
      });
    },
    [services]
  );

  return (
    <AnalyticsContextProvider
      onUpdateCookieConsent={handleUpdatedCookieConsent}
      onEvent={handleEvent}
    >
      {children}
    </AnalyticsContextProvider>
  );
};
