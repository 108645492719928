import type { AuthMethod, MockUser } from '@apis/authy';
import { createApiClient } from '@apis/authy';
import { getConfigurationVariable } from '@packages/config';
import { isServer } from '@packages/gatsby-utils';
import { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useLocalStorage } from 'react-use';
import { attachAuthAxiosInterceptors } from './attachAxiosInterceptors';

const isApp = (): boolean =>
  Boolean(
    !!Cookies.get('kronan-app-only') &&
      !isServer() &&
      window &&
      window.location &&
      window.location.href &&
      window.location.href.includes('external')
  );

interface ContextMethods {
  init: () => void;
  authenticateAxiosClient: (
    client: AxiosInstance,
    convertError?: (error: unknown) => Promise<void>
  ) => () => void;
  fakeSignIn: (redirectUrl: string, user: MockUser) => Promise<void>;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
}

interface AuthContextType extends ContextMethods {
  isSignedIn: boolean;
  isInitialized: boolean;
}

const noAuthProviderFoundFn = () => {
  throw new Error('No auth provider found, did you forget to wrap your app in AuthProvider?');
};

export const AuthContext = createContext<AuthContextType>({
  isSignedIn: false,
  isInitialized: false,
  init: noAuthProviderFoundFn,
  fakeSignIn: noAuthProviderFoundFn,
  signIn: noAuthProviderFoundFn,
  signOut: noAuthProviderFoundFn,
  authenticateAxiosClient: noAuthProviderFoundFn,
});

export type { AuthMethod };

interface State {
  isActivated: boolean;
  isInitialized: boolean;
  isFetching: boolean;
  data: {
    accessToken: string;
    expiresAt: number;
  } | null;
}

const initialState: State = {
  isActivated: false,
  isInitialized: false,
  isFetching: false,
  data: null,
};

interface Activate {
  type: 'ACTIVATE';
}

interface AccessTokenFound {
  type: 'ACCESS_TOKEN_FOUND';
  payload: {
    accessToken: string;
    expiresAt: number;
  };
}

interface FetchAccessToken {
  type: 'FETCH_ACCESS_TOKEN';
}

interface FetchAccessTokenSuccess {
  type: 'FETCH_ACCESS_TOKEN_SUCCESS';
  payload: {
    accessToken: string;
    expiresAt: number;
  };
}

interface FetchAccessTokenError {
  type: 'FETCH_ACCESS_TOKEN_ERROR';
}

interface Logout {
  type: 'LOGOUT';
}

type Action =
  | Activate
  | AccessTokenFound
  | FetchAccessToken
  | FetchAccessTokenSuccess
  | FetchAccessTokenError
  | Logout;

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ACTIVATE':
      return {
        ...state,
        isActivated: true,
      };
    case 'ACCESS_TOKEN_FOUND':
      return {
        ...state,
        isInitialized: true,
        data: action.payload,
      };
    case 'FETCH_ACCESS_TOKEN':
      return {
        ...state,
        isFetching: true,
      };
    case 'FETCH_ACCESS_TOKEN_SUCCESS':
      return {
        ...state,
        isFetching: false,
        isInitialized: true,
        data: action.payload,
      };
    case 'FETCH_ACCESS_TOKEN_ERROR':
      return {
        ...state,
        isFetching: false,
        isInitialized: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

const TWO_MINUTES = 1000 * 60 * 2;

const getExpiresAtFromExpiresIn = (expiresIn: number) => Date.now() + expiresIn * 1000;

const AUTHY_DOMAIN = getConfigurationVariable('AUTHY_DOMAIN');
const AUTHY_PATH = getConfigurationVariable('AUTHY_BASE_PATH');

export const authyApiClient = createApiClient({ domain: AUTHY_DOMAIN, basePath: AUTHY_PATH });
export interface Props {
  onSignOut?: () => void;
}

export const AuthProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onSignOut = noAuthProviderFoundFn,
}) => {
  const onSignOutRef = useRef<() => void>(onSignOut);

  useEffect(() => {
    onSignOutRef.current = onSignOut;
  }, [onSignOut]);

  const { getAccessToken, getFakeLoginUrl, logout } = authyApiClient;

  const [dataFromStorage, setDataFromStorage, clearStorage] =
    useLocalStorage<State['data']>('authy-access-token');

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (dataFromStorage && !state.data) {
      const isExpired = Date.now() > dataFromStorage.expiresAt;
      if (!isExpired) {
        dispatch({ type: 'ACCESS_TOKEN_FOUND', payload: dataFromStorage });
      }
    }
  }, [dataFromStorage, state.data]);

  const fetchAccessToken = useCallback(async () => {
    dispatch({ type: 'FETCH_ACCESS_TOKEN' });

    try {
      const { token, expiresIn } = await getAccessToken();

      setDataFromStorage({ accessToken: token, expiresAt: getExpiresAtFromExpiresIn(expiresIn) });

      dispatch({
        type: 'FETCH_ACCESS_TOKEN_SUCCESS',
        payload: { accessToken: token, expiresAt: getExpiresAtFromExpiresIn(expiresIn) },
      });

      return token;
    } catch (error) {
      dispatch({ type: 'FETCH_ACCESS_TOKEN_ERROR' });
    }
  }, [getAccessToken, setDataFromStorage]);

  useEffect(() => {
    const hasPreviousData = dataFromStorage && dataFromStorage.expiresAt > Date.now();

    if (
      state.isActivated &&
      !state.isInitialized &&
      !hasPreviousData &&
      !state.isFetching &&
      !state.data
    ) {
      fetchAccessToken();
    }
  }, [
    dataFromStorage,
    fetchAccessToken,
    state.data,
    state.isActivated,
    state.isFetching,
    state.isInitialized,
  ]);

  useEffect(() => {
    if (state.data) {
      const timeUntilExpiry = state.data.expiresAt - Date.now();
      const timeout = setTimeout(() => {
        fetchAccessToken();
      }, timeUntilExpiry - TWO_MINUTES);

      return () => clearTimeout(timeout);
    }
  }, [fetchAccessToken, state.data]);

  const latestAccessToken = useRef(state.data?.accessToken);

  useEffect(() => {
    latestAccessToken.current = state.data?.accessToken;
  }, [state.data?.accessToken]);

  const signOut = useCallback(async () => {
    await logout();
    clearStorage();
    dispatch({
      type: 'LOGOUT',
    });

    onSignOutRef.current();
  }, [clearStorage, logout]);

  const authenticateAxiosClient = useCallback(
    (axiosClient: AxiosInstance, convertError?: (error: unknown) => void) => {
      return attachAuthAxiosInterceptors(axiosClient, {
        getCurrentToken: () => latestAccessToken.current,
        refreshToken: fetchAccessToken,
        onUnauthorizedError: () => {
          if (isApp()) {
            const message = { type: 'TOKEN_ERROR' };
            window.postMessage(message);
          } else {
            signOut();
          }
        },
        convertError,
      });
    },
    [fetchAccessToken, signOut]
  );

  const fakeSignIn = useCallback(
    async (redirectUrl: string, user: MockUser) => {
      const redirectUrlObject = new URL(redirectUrl);
      window.location.assign(getFakeLoginUrl(redirectUrlObject.pathname, user));
    },
    [getFakeLoginUrl]
  );

  const signIn = useCallback(async () => {
    await fetchAccessToken();
  }, [fetchAccessToken]);

  const init = useCallback(() => {
    dispatch({
      type: 'ACTIVATE',
    });
  }, []);

  const isSignedIn = !!state.data;

  const contextValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      init,
      isSignedIn,
      signOut,
      authenticateAxiosClient,
      fakeSignIn,
      signIn,
    }),
    [state.isInitialized, init, isSignedIn, signOut, authenticateAxiosClient, fakeSignIn, signIn]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const { init, ...rest } = useContext(AuthContext);

  useEffect(() => {
    init();
  }, [init]);

  return rest;
};
