import { PRODUCT_MONITORING_POSTED } from '../../../../../../components/pages/productPage/analytics';
import { Tracker } from '../../../types';
import { createSimpleTracker } from '../utils/createSimpleTracker';

type ProductMonitoringTracker = {
  event: string;
  event_info: {
    event_name: string;
    user_interaction: 'success' | 'fail';
  };
};

export const productMonitoringTracker: Tracker = createSimpleTracker({
  [PRODUCT_MONITORING_POSTED]: ({ event }) =>
    ({
      event: 'bevaka_produkt',
      event_info: {
        event_name: 'bevaka_produkt',
        user_interaction: event.payload.formIsPosted ? 'success' : 'fail',
      },
    } as ProductMonitoringTracker),
});
