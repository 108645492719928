import { AuthMethod } from '@apis/authy';

export const OLD_MEMBER_LOGIN = 'OLD_MEMBER/LOGIN';

export interface OldMemberLogin {
  type: typeof OLD_MEMBER_LOGIN;
  payload: {
    provider: 'BankId';
    label: string;
    elementType: 'link' | 'button';
    method: AuthMethod;
    location: 'loginPage';
  };
}

export const OLD_MEMBER_OPEN_LOGIN_MODAL = 'OLD_MEMBER/OPEN_LOGIN_MODAL';

export interface OldMemberOpenLoginModal {
  type: typeof OLD_MEMBER_OPEN_LOGIN_MODAL;
  payload: {
    linkType: 'button';
    linkText: string;
  };
}

export type AnalyticsEvents = OldMemberLogin | OldMemberOpenLoginModal;
